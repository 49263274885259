import React from "react";
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { useSiteMetadata } from "../hooks/use-site-metadata"

// this routing page is for SEO purpose

function ProductRoute({ pageContext }) {
  const { product } = pageContext

  const productParsed = JSON.parse(product);
  const {ultid, title, description,image} = JSON.parse(productParsed.internal.content)

  const { product_url } = useSiteMetadata()  
  const canonicalLink=product_url+'ultid/'+ultid
  
  return (
    <>
    <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={canonicalLink} />
        <meta property="og:type" content="website" />
        <meta name="description" content={description}/>
        <link rel="canonical" href={canonicalLink}></link>
    </Helmet>


    {
        ((typeof window !== "undefined") && (!!ultid)) && (navigate("/product/?ultid="+ultid))  // const isBrowser = typeof window !== "undefined"  // this is the way to do auto-routing
    }

    {
        ((typeof window !== "undefined") && !(!!ultid)) && (navigate("/products/") ) // const isBrowser = typeof window !== "undefined"  // this is the way to do auto-routing
    }
    </>
  )
}

export default ProductRoute